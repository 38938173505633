
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('bootstrap-slider');

require('datatables');

// require('handlebars');

import 'handlebars/dist/handlebars.min.js';


import { createApp } from 'vue'

const app = createApp({})

app.component('app-header', require('../../js/cla-vue-template/src/components/AppHeader.vue').default);
app.component('navbar-item', require('../../js/cla-vue-template/src/components/NavbarItem.vue').default);
app.component('navbar', require('../../js/cla-vue-template/src/components/Navbar.vue').default);
app.component('app-footer', require('../../js/cla-vue-template/src/components/AppFooter.vue').default);
app.component('postit', require('../../js/cla-vue-template/src/components/PostIt.vue').default);


app.mount('#app')

const app2 = createApp({})
app2.component('app-footer', require('../../js/cla-vue-template/src/components/AppFooter.vue').default);
app2.mount('#appfooter')