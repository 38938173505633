<template>
    <a href="https://umn.edu" class="wordmark-link">
        <svg class="umn-wordmark" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1" id="wordmark-full" x="0px" y="0px" viewBox="0 0 285.87 37.96"
            style="enable-background:new 0 0 285.87 37.96;" xml:space="preserve">
            <title>UMN Wordmark</title>
            <g class="wordmark block_m">
                <path class="fill__maroon"
                    d="M54.65,29.11L44.43,12.12h2.82V3.26H30.24v8.85h2.82l-3.91,6.49l-3.9-6.49h2.81V3.26H11.05v8.85h2.81    L3.65,29.11H0v8.85h17.35v-8.85h-3.12l5.32-8.85l2.06,3.43h-1.98v8.85h19.02v-8.85h-1.98l2.07-3.43l5.32,8.85h-3.11v8.85H58.3    v-8.85H54.65z">
                </path>
            </g>

            <g class="wordmark u-of-m">
                <path class="fill__dark-gray"
                    d="M276.46,3.48l0.08,2.72h-0.36c-0.33-1.75-0.75-2.03-3.05-2.03v8.13c0,0.95,0.49,1.37,1.37,1.37v0.38h-4.26    v-0.38c0.89,0,1.37-0.42,1.37-1.37V4.17c-2.3,0-2.72,0.29-3.05,2.03h-0.35l0.08-2.72H276.46z">
                </path>
                <path class="fill__dark-gray"
                    d="M262.33,3.28c-2.61,0-4.72,2.15-4.72,5.48s2.11,5.48,4.72,5.48s4.72-2.14,4.72-5.48S264.94,3.28,262.33,3.28z     M262.33,13.62c-1.98,0-3.11-1.9-3.11-4.86c0-2.95,1.13-4.86,3.11-4.86c1.98,0,3.11,1.9,3.11,4.86    C265.45,11.71,264.32,13.62,262.33,13.62z">
                </path>
                <path class="fill__dark-gray"
                    d="M284.71,12.76c-0.19-0.49-3.74-9.42-3.74-9.42h-0.36c0,0-3.13,8.19-3.52,9.15c-0.39,0.96-0.85,1.2-1.22,1.2    v0.38h2.88v-0.38c-0.42,0-0.9-0.3-0.9-0.76c0-0.14,0.01-0.3,0.05-0.4c0.03-0.08,0.33-0.88,0.7-1.91h3.66    c0.37,1.02,0.66,1.8,0.67,1.84c0.05,0.14,0.11,0.33,0.11,0.47c0,0.41-0.33,0.76-0.95,0.76v0.38h3.71v-0.38    C285.37,13.68,284.9,13.25,284.71,12.76z M278.89,9.87c0.72-1.94,1.56-4.23,1.56-4.23s0.85,2.3,1.55,4.23H278.89z">
                </path>
                <path class="fill__dark-gray"
                    d="M247.09,11.31c-0.47,1.1-1.07,2.06-2.75,2.06c-0.36,0-1.1,0-1.68,0c-0.61,0-0.88-0.21-0.88-0.72    c0-0.2,0-1.94,0-3.77c0.41,0,1.67,0,2.28,0c0.88,0,1.35,0.5,1.48,1.43h0.36V6.67h-0.38c-0.05,1.02-0.58,1.51-1.37,1.51    c-0.68,0-1.98,0-2.36,0c0-1.74,0-3.37,0-3.54c0-0.39,0.25-0.47,0.58-0.47c0.33,0,1.07,0,2.03,0c1.26,0,1.7,0.52,1.98,1.7h0.36    l-0.08-2.39h-7.61v0.39c0.89,0,1.29,0.42,1.29,1.37v7.06c0,0.95-0.41,1.37-1.29,1.37v0.38h7.8l0.68-2.75H247.09z">
                </path>
                <path class="fill__dark-gray"
                    d="M255.14,3.27l0.3,3.49h-0.39c-0.52-1.72-1.4-2.78-2.66-2.78c-0.91,0-1.43,0.61-1.43,1.43    c0,0.93,0.69,1.48,1.54,2.06c0.85,0.58,1.43,0.96,1.7,1.15c0.79,0.55,1.54,1.54,1.54,2.67c0,1.72-1.29,2.99-2.99,2.99    c-1.04,0-1.7-0.52-2.23-0.52c-0.29,0-0.43,0.29-0.49,0.52h-0.36l-0.44-3.52h0.39c0.64,1.8,1.59,2.86,2.91,2.86    c0.99,0,1.73-0.68,1.73-1.78c0-1-0.53-1.48-1.15-1.92c-0.7-0.49-1.56-1.1-2.09-1.46c-0.82-0.56-1.32-1.31-1.32-2.53    c0-1.46,0.96-2.66,2.61-2.66c0.71,0,1.51,0.47,1.95,0.47c0.33,0,0.47-0.22,0.49-0.47H255.14z">
                </path>
                <path class="fill__dark-gray"
                    d="M234.12,3.48v0.39c0.82,0,1.32,0.63,1.32,1.59c0,0.65,0,3.68,0,5.56l-5.49-7.54h-2.39v0.39    c0.47,0,0.85,0.22,1.04,0.47c0.02,0.03,0.13,0.18,0.3,0.41c0,1.15,0,6.15,0,7.1c0,1.21-0.41,1.84-1.37,1.84v0.38h3.38v-0.38    c-0.83,0-1.32-0.55-1.32-1.51c0-0.78,0-4.94,0-6.49c2,2.7,6.32,8.56,6.32,8.56h0.22c0,0,0-7.45,0-8.52c0-1.35,0.55-1.84,1.37-1.84    V3.48H234.12z">
                </path>
                <path class="fill__dark-gray"
                    d="M222.39,3.48v0.39c0.83,0,1.32,0.63,1.32,1.59c0,0.65,0,3.68,0,5.56l-5.49-7.54h-2.39v0.39    c0.47,0,0.85,0.22,1.04,0.47c0.02,0.03,0.13,0.18,0.3,0.41c0,1.15,0,6.15,0,7.1c0,1.21-0.41,1.84-1.37,1.84v0.38h3.38v-0.38    c-0.83,0-1.32-0.55-1.32-1.51c0-0.78,0-4.94,0-6.49c2,2.7,6.32,8.56,6.32,8.56h0.22c0,0,0-7.45,0-8.52c0-1.35,0.55-1.84,1.37-1.84    V3.48H222.39z">
                </path>
                <path class="fill__dark-gray"
                    d="M208.21,0.44V0.05h-3.27l-4.26,11.43L196.4,0.05h-3.33v0.39c1.4,0,1.79,0.77,1.79,2.14s0,8.32,0,8.6    c0,1.99-0.56,2.5-1.81,2.5v0.38h4.31v-0.38c-1.21,0-1.79-0.52-1.79-2.5c0-1.98,0-8.98,0-8.98l4.45,11.9h0.41l4.54-12.11    c0,0,0,8.85,0,9.2c0,1.99-0.45,2.5-1.71,2.5v0.38h4.94v-0.38c-1.21,0-1.67-0.52-1.67-2.5c0-0.77,0-7.64,0-8.79    C206.54,0.79,207.39,0.44,208.21,0.44z">
                </path>
                <path class="fill__dark-gray"
                    d="M212.25,5.24c0-0.95,0.49-1.37,1.37-1.37V3.48h-4.26v0.39c0.89,0,1.37,0.42,1.37,1.37v7.06    c0,0.95-0.49,1.37-1.37,1.37v0.38h4.26v-0.38c-0.89,0-1.37-0.42-1.37-1.37V5.24z">
                </path>
                <path class="fill__dark-gray"
                    d="M173.25,3.29c-2.61,0-4.72,2.15-4.72,5.48c0,3.33,2.12,5.48,4.72,5.48c2.61,0,4.72-2.14,4.72-5.48    C177.98,5.44,175.86,3.29,173.25,3.29z M173.25,13.63c-1.98,0-3.11-1.9-3.11-4.86c0-2.96,1.13-4.86,3.11-4.86    c1.98,0,3.11,1.9,3.11,4.86C176.37,11.73,175.23,13.63,173.25,13.63z">
                </path>
                <path class="fill__dark-gray"
                    d="M187.14,3.48h-7.69v0.39c0.89,0,1.29,0.42,1.29,1.37v7.06c0,0.95-0.4,1.37-1.29,1.37v0.38h4.09v-0.38    c-0.85,0-1.35-0.41-1.35-1.37c0-0.19,0-1.75,0-3.41c0.4,0,1.59,0,2.2,0c0.88,0,1.35,0.5,1.49,1.43h0.36V6.67h-0.38    c-0.05,1.02-0.58,1.51-1.37,1.51c-0.67,0-1.91,0-2.28,0c0-1.72,0-3.36,0-3.54c0-0.39,0.25-0.47,0.58-0.47c0.33,0,1.15,0,2.12,0    c1.26,0,1.7,0.52,1.98,1.7h0.36L187.14,3.48z">
                </path>
                <path class="fill__dark-gray"
                    d="M151.82,3.5l0.08,2.72h-0.36c-0.33-1.74-0.76-2.03-3.05-2.03v8.13c0,0.95,0.49,1.37,1.37,1.37v0.38h-4.26    v-0.38c0.89,0,1.37-0.42,1.37-1.37V4.19c-2.29,0-2.72,0.29-3.05,2.03h-0.36l0.08-2.72H151.82z">
                </path>
                <path class="fill__dark-gray"
                    d="M159.98,3.88V3.5h3.24v0.38c-0.47,0-1.2,0.51-1.51,1.02c-0.34,0.56-2.61,4.34-2.61,4.34v3.08    c0,1.03,0.38,1.37,1.48,1.37v0.38h-4.48v-0.38c1.11,0,1.48-0.35,1.48-1.37V9.41c0,0-2.5-3.99-2.8-4.48    c-0.3-0.49-0.91-1.04-1.48-1.04V3.5h4.18v0.38c-0.58,0-0.99,0.26-0.99,0.56c0,0.2,0.1,0.43,0.17,0.55    c0.07,0.12,2.12,3.49,2.12,3.49s2.03-3.53,2.09-3.62c0.06-0.1,0.08-0.22,0.08-0.34C160.94,4.23,160.78,3.88,159.98,3.88z">
                </path>
                <path class="fill__dark-gray"
                    d="M134.95,3.27l0.3,3.49h-0.38c-0.52-1.72-1.4-2.78-2.66-2.78c-0.91,0-1.43,0.61-1.43,1.43    c0,0.93,0.69,1.48,1.54,2.06c0.85,0.58,1.43,0.96,1.7,1.15c0.79,0.55,1.54,1.54,1.54,2.67c0,1.72-1.29,2.99-2.99,2.99    c-1.05,0-1.7-0.52-2.23-0.52c-0.29,0-0.42,0.29-0.49,0.52h-0.36l-0.44-3.52h0.39c0.64,1.8,1.59,2.86,2.91,2.86    c0.99,0,1.73-0.68,1.73-1.78c0-1-0.53-1.48-1.15-1.92c-0.7-0.49-1.56-1.1-2.09-1.46c-0.82-0.56-1.32-1.31-1.32-2.53    c0-1.46,0.96-2.66,2.61-2.66c0.71,0,1.51,0.47,1.95,0.47c0.33,0,0.47-0.22,0.49-0.47H134.95z">
                </path>
                <path class="fill__dark-gray"
                    d="M140.78,5.26c0-0.95,0.49-1.37,1.37-1.37V3.5h-4.26v0.38c0.89,0,1.37,0.42,1.37,1.37v7.06    c0,0.95-0.49,1.37-1.37,1.37v0.38h4.26v-0.38c-0.89,0-1.37-0.42-1.37-1.37V5.26z">
                </path>
                <path class="fill__dark-gray"
                    d="M116.55,11.31c-0.47,1.1-1.07,2.06-2.75,2.06c-0.36,0-1.1,0-1.68,0c-0.61,0-0.88-0.21-0.88-0.72    c0-0.2,0-1.94,0-3.77c0.41,0,1.67,0,2.28,0c0.88,0,1.35,0.5,1.48,1.43h0.36V6.67h-0.38c-0.06,1.02-0.58,1.51-1.37,1.51    c-0.68,0-1.98,0-2.36,0c0-1.74,0-3.37,0-3.54c0-0.39,0.25-0.47,0.58-0.47c0.33,0,1.07,0,2.03,0c1.26,0,1.7,0.52,1.98,1.7h0.36    l-0.08-2.39h-7.61v0.39c0.89,0,1.29,0.42,1.29,1.37v7.06c0,0.95-0.4,1.37-1.29,1.37v0.38h7.8l0.69-2.75H116.55z">
                </path>
                <path class="fill__dark-gray"
                    d="M126.6,13.21c-0.25-0.29-2.59-3.51-3.11-4.23c1.89-0.4,2.56-1.37,2.56-2.75c0-1.65-1.24-2.75-3.49-2.75    c-0.27,0-4.23,0-4.23,0v0.39c0.89,0,1.29,0.42,1.29,1.37v7.06c0,0.95-0.41,1.37-1.29,1.37v0.38h4.09v-0.38    c-0.89,0-1.29-0.42-1.29-1.37V9.2c0.28,0,0.55-0.01,0.8-0.02l3.43,4.89h2.31v-0.33C127.29,13.73,126.88,13.54,126.6,13.21z     M121.13,8.57V4.99c0-0.73,0.15-0.91,0.96-0.91c1.79,0,2.44,0.88,2.44,2.2c0,1.62-1.23,2.28-3.05,2.28    C121.35,8.57,121.24,8.57,121.13,8.57z">
                </path>
                <path class="fill__dark-gray"
                    d="M94.13,5.24c0-0.95,0.48-1.37,1.37-1.37V3.48h-4.26v0.39c0.89,0,1.37,0.42,1.37,1.37v7.06    c0,0.95-0.49,1.37-1.37,1.37v0.38h4.26v-0.38c-0.89,0-1.37-0.42-1.37-1.37V5.24z">
                </path>
                <path class="fill__dark-gray"
                    d="M101.11,3.87V3.48h-3.87v0.39c0.47,0,0.96,0.3,1.29,1.1c0.33,0.79,3.82,9.26,3.82,9.26h0.25    c0,0,3.05-8.52,3.33-9.26c0.27-0.74,0.74-1.1,1.18-1.1V3.48h-2.99v0.39c0.66,0,1.02,0.36,1.02,0.82c0,0.14-0.04,0.29-0.08,0.41    c-0.06,0.16-2.22,6.24-2.22,6.24s-2.56-6.14-2.63-6.32c-0.06-0.14-0.09-0.32-0.09-0.48C100.09,4.12,100.44,3.87,101.11,3.87z">
                </path>
                <path class="fill__dark-gray"
                    d="M65.61,0.44c1.15,0,1.76,0.58,1.76,2.22c0,1.65,0,3.82,0,6.54c0,3.3,1.81,5.06,4.97,5.06    c2.72,0,4.81-1.65,4.81-4.84c0-2.94,0-6.04,0-7.14c0-1.1,0.33-1.84,1.65-1.84V0.05h-4.18v0.39c1.18,0,1.67,0.52,1.7,1.95    c0.03,1.43,0.11,5.17,0.11,7.39c0,2.22-1.59,3.62-3.76,3.62c-2.09,0-3.74-1.24-3.74-3.85s0-6.16,0-7.31    c0-1.08,0.41-1.81,1.65-1.81V0.05h-4.97V0.44z">
                </path>
                <path class="fill__dark-gray"
                    d="M85.67,3.48v0.39c0.82,0,1.32,0.63,1.32,1.59c0,0.65,0,3.68,0,5.56l-5.49-7.54H79.1v0.39    c0.47,0,0.85,0.22,1.05,0.47c0.02,0.03,0.13,0.18,0.3,0.41c0,1.15,0,6.15,0,7.1c0,1.21-0.41,1.84-1.37,1.84v0.38h3.38v-0.38    c-0.82,0-1.32-0.55-1.32-1.51c0-0.78,0-4.94,0-6.49c2,2.7,6.32,8.56,6.32,8.56h0.22c0,0,0-7.45,0-8.52c0-1.35,0.55-1.84,1.37-1.84    V3.48H85.67z">
                </path>
            </g>

            <g class="wordmark d-2-d">
                <path class="fill__maroon"
                    d="M93.98,24.97h3.93c3.93,0,6.73,2.61,6.73,6.39c0,3.78-2.78,6.39-6.73,6.39h-3.93V24.97z M97.78,35.44      c2.84,0,4.13-1.69,4.13-4.08c0-2.38-1.3-4.08-4.13-4.08h-1.16v8.15H97.78z">
                </path>
                <path class="fill__maroon"
                    d="M107.01,29.75h2.54l-0.04,1.45c0.58-1.32,1.52-1.62,2.35-1.62c0.53,0,0.92,0.15,1.24,0.32l-0.81,2.31      c-0.32-0.15-0.62-0.24-0.98-0.24c-1.15,0-1.77,0.53-1.77,2.24v3.55h-2.54V29.75z">
                </path>
                <path class="fill__maroon"
                    d="M115.07,26.82c0-0.92,0.75-1.58,1.62-1.58c0.86,0,1.6,0.66,1.6,1.58c0,0.94-0.73,1.6-1.6,1.6      C115.82,28.41,115.07,27.75,115.07,26.82z M115.41,29.75h2.54v8h-2.54V29.75z">
                </path>
                <path class="fill__maroon"
                    d="M120.14,29.75h2.8l1.13,2.39c0.38,0.81,0.66,2.12,0.66,2.12s0.26-1.31,0.62-2.12l1.09-2.39h2.57l-4.28,8.17      h-0.24L120.14,29.75z">
                </path>
                <path class="fill__maroon"
                    d="M135.03,35.82c0.92,0,1.62-0.36,2.01-0.64l1.45,1.56c-0.77,0.68-1.75,1.18-3.42,1.18      c-2.86,0-4.7-1.73-4.7-4.19c0-2.42,1.94-4.15,4.42-4.15c2.87,0,4.44,2.14,4.25,4.94h-6.07      C133.21,35.35,133.88,35.82,135.03,35.82z M136.48,33.05c-0.11-0.88-0.68-1.41-1.67-1.41c-1,0-1.67,0.45-1.88,1.41H136.48z">
                </path>
                <path class="fill__maroon"
                    d="M141.7,29.75h2.54l-0.06,1.31c0.49-0.96,1.43-1.48,2.54-1.48c1.75,0,3.01,1.28,3.01,3.55v4.62h-2.54V33.3      c0-0.9-0.43-1.41-1.33-1.41c-1.01,0-1.62,0.56-1.62,1.58v4.29h-2.54V29.75z">
                </path>
                <path class="fill__maroon"
                    d="M158.46,34.75v-2.9h-1.18v-2.1h1.18v-4.17h2.52v4.17h2.05v2.1h-2.05v2.97c0,0.81,0.26,0.97,0.66,0.97      c0.41,0,0.71-0.22,0.88-0.34l0.96,1.77c-0.4,0.32-1.07,0.73-2.24,0.73C159.62,37.96,158.46,37.15,158.46,34.75z">
                </path>
                <path class="fill__maroon"
                    d="M165.34,33.77c0-2.44,1.94-4.19,4.42-4.19c2.48,0,4.4,1.75,4.4,4.19c0,2.43-1.92,4.15-4.4,4.15      C167.27,37.92,165.34,36.19,165.34,33.77z M171.56,33.77c0-1.2-0.67-2.01-1.8-2.01c-1.13,0-1.82,0.81-1.82,2.01      c0,1.18,0.69,2.01,1.82,2.01C170.88,35.78,171.56,34.95,171.56,33.77z">
                </path>
                <path class="fill__maroon"
                    d="M182.23,24.97h3.93c3.93,0,6.73,2.61,6.73,6.39c0,3.78-2.78,6.39-6.73,6.39h-3.93V24.97z M186.03,35.44      c2.84,0,4.13-1.69,4.13-4.08c0-2.38-1.3-4.08-4.13-4.08h-1.16v8.15H186.03z">
                </path>
                <path class="fill__maroon"
                    d="M195.46,26.82c0-0.92,0.75-1.58,1.61-1.58c0.86,0,1.59,0.66,1.59,1.58c0,0.94-0.73,1.6-1.59,1.6      C196.21,28.41,195.46,27.75,195.46,26.82z M195.8,29.75h2.54v8h-2.54V29.75z">
                </path>
                <path class="fill__maroon"
                    d="M200.78,36.4l1.48-1.31c0.55,0.53,1.24,0.84,1.99,0.84c0.83,0,1.09-0.15,1.09-0.49      c0-0.43-0.51-0.43-1.81-0.77c-1.09-0.28-2.31-0.88-2.31-2.55c0-1.8,1.39-2.54,3.1-2.54c1.31,0,2.26,0.43,2.91,1.11l-1.35,1.45      c-0.38-0.32-0.94-0.6-1.54-0.6c-0.58,0-0.86,0.15-0.86,0.47c0,0.45,0.51,0.51,1.6,0.75c1.16,0.27,2.63,0.81,2.63,2.5      c0,1.47-0.77,2.67-3.29,2.67C202.56,37.92,201.47,37.22,200.78,36.4z">
                </path>
                <path class="fill__maroon"
                    d="M209.93,33.73c0-2.42,1.88-4.15,4.38-4.15c1.6,0,2.55,0.58,3.36,1.6l-1.8,1.47      c-0.3-0.49-0.92-0.85-1.56-0.85c-1.14,0-1.79,0.81-1.79,1.94c0,1.16,0.64,1.99,1.79,1.99c0.81,0,1.24-0.37,1.62-0.75l1.71,1.5      c-0.79,0.88-1.73,1.45-3.33,1.45C211.81,37.92,209.93,36.19,209.93,33.73z">
                </path>
                <path class="fill__maroon"
                    d="M219.64,33.77c0-2.44,1.94-4.19,4.42-4.19c2.48,0,4.4,1.75,4.4,4.19c0,2.43-1.92,4.15-4.4,4.15      C221.58,37.92,219.64,36.19,219.64,33.77z M225.87,33.77c0-1.2-0.68-2.01-1.8-2.01c-1.13,0-1.82,0.81-1.82,2.01      c0,1.18,0.7,2.01,1.82,2.01C225.19,35.78,225.87,34.95,225.87,33.77z">
                </path>
                <path class="fill__maroon"
                    d="M229.81,29.75h2.8l1.13,2.39c0.38,0.81,0.66,2.12,0.66,2.12s0.26-1.31,0.62-2.12l1.09-2.39h2.57l-4.29,8.17      h-0.24L229.81,29.75z">
                </path>
                <path class="fill__maroon"
                    d="M244.7,35.82c0.92,0,1.62-0.36,2.01-0.64l1.45,1.56c-0.77,0.68-1.75,1.18-3.42,1.18      c-2.86,0-4.7-1.73-4.7-4.19c0-2.42,1.94-4.15,4.42-4.15c2.88,0,4.44,2.14,4.25,4.94h-6.07      C242.87,35.35,243.55,35.82,244.7,35.82z M246.14,33.05c-0.11-0.88-0.68-1.41-1.67-1.41c-1,0-1.67,0.45-1.88,1.41H246.14z">
                </path>
                <path class="fill__maroon"
                    d="M251.37,29.75h2.54l-0.04,1.45c0.58-1.32,1.52-1.62,2.35-1.62c0.53,0,0.92,0.15,1.24,0.32l-0.81,2.31      c-0.32-0.15-0.62-0.24-0.97-0.24c-1.15,0-1.77,0.53-1.77,2.24v3.55h-2.54V29.75z">
                </path>
                <path class="fill__maroon"
                    d="M262.5,28.41c0.28,0.11,0.52,0.27,0.72,0.47c0.2,0.2,0.36,0.42,0.48,0.66c0.15,0.31,0.23,0.65,0.23,1.01     c0,0.4-0.1,0.79-0.3,1.15c-0.2,0.37-0.49,0.65-0.85,0.85c-0.36,0.2-0.74,0.3-1.14,0.3c-0.39,0-0.77-0.1-1.13-0.3     c-0.36-0.2-0.64-0.49-0.85-0.85c-0.2-0.36-0.3-0.75-0.3-1.15c0-0.34,0.07-0.66,0.2-0.94c0.13-0.28,0.3-0.52,0.49-0.71     c0.2-0.19,0.41-0.34,0.65-0.45c0.31-0.14,0.62-0.21,0.94-0.21C261.94,28.25,262.22,28.3,262.5,28.41z M264.12,29.43     c-0.12-0.28-0.3-0.53-0.53-0.77c-0.23-0.24-0.51-0.43-0.84-0.58c-0.33-0.16-0.7-0.23-1.1-0.23c-0.41,0-0.81,0.09-1.18,0.27     c-0.27,0.13-0.52,0.31-0.75,0.53c-0.23,0.23-0.41,0.5-0.56,0.84c-0.15,0.33-0.22,0.69-0.22,1.07c0,0.47,0.12,0.92,0.36,1.35     c0.24,0.43,0.57,0.76,1,1c0.43,0.24,0.88,0.36,1.35,0.36c0.42,0,0.81-0.09,1.18-0.27c0.29-0.14,0.55-0.33,0.78-0.57     c0.24-0.24,0.42-0.53,0.55-0.86c0.13-0.33,0.19-0.67,0.19-1.01C264.36,30.17,264.28,29.79,264.12,29.43z M261.56,30.94     c0.17,0,0.3,0.02,0.39,0.07c0.09,0.05,0.15,0.11,0.19,0.19c0.04,0.08,0.06,0.21,0.08,0.39c0.01,0.25,0.03,0.4,0.05,0.45h0.64     c-0.03-0.08-0.04-0.21-0.05-0.4c0-0.18-0.01-0.31-0.03-0.38c-0.03-0.12-0.07-0.22-0.12-0.3c-0.06-0.08-0.12-0.14-0.21-0.19     c-0.08-0.05-0.19-0.08-0.33-0.11c0.16-0.02,0.27-0.04,0.33-0.08c0.12-0.06,0.22-0.15,0.29-0.26c0.07-0.12,0.11-0.25,0.11-0.4     c0-0.15-0.03-0.29-0.1-0.41c-0.07-0.12-0.16-0.21-0.26-0.28c-0.11-0.07-0.25-0.12-0.42-0.15c-0.12-0.02-0.3-0.03-0.53-0.03h-1.07     v2.99h0.67v-1.1H261.56z M261.91,29.56c0.1,0.03,0.18,0.08,0.24,0.15c0.06,0.07,0.09,0.16,0.09,0.26c0,0.1-0.02,0.18-0.08,0.26     c-0.05,0.08-0.12,0.14-0.22,0.17c-0.1,0.04-0.21,0.06-0.32,0.06h-0.44v-0.95h0.44C261.71,29.51,261.81,29.53,261.91,29.56z">
                </path>
            </g>

        </svg>
    </a>
</template>

<style scoped>

.d-2-d {
    display: none;
}

svg.umn-wordmark .u-of-m {
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
}

.fill__maroon {
    fill: #7A0019;
}

.fill__dark-gray {
    fill: #231F20;
    stroke: #000000;
    stroke-width: 0.1;
    stroke-miterlimit: 10;
}
    @media (min-width: 49em) {
        svg.umn-wordmark .d-2-d {
            display: block;
        }

        svg.umn-wordmark .u-of-m {
            -webkit-transform: unset;
            transform: unset;
        }
    }
    .wordmark-link {
        flex: 1 1 auto;
        display: flex;
        position: relative;
        align-items: center;
        max-width: 21.25rem;
        padding: 0.25rem;
    }

    .wordmark-link svg {
        width: 100%;
        height: auto;
        min-height: 1px;
        max-width: 100%;
        display: block;
        overflow: hidden;
    }
</style>

<script>
    export default {
        name: 'UMNWordmark',
        components: {},
        props: {},
        setup() {

        },
    };
</script>
